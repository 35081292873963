import { Link } from "gatsby";
import Helmet from "react-helmet";
import React, { FunctionComponent } from "react";
import { Segment, Menu, Container, Grid, List, Divider } from "semantic-ui-react";

type Props = {
  children: JSX.Element[] | JSX.Element;
  section: string;
  title: string;
  description?: string;
  keywords?: string;
};

const Component: FunctionComponent<Props> = ({
  children,
  section,
  title,
  description,
  keywords,
}) => (
  <>
    <Helmet
      title={title ? `${title}` : "CYM-Identity"}
      meta={[
        {
          name: "description",
          content: description ? description : "",
        },
        {
          name: "keywords",
          content: keywords
            ? keywords
            : "oauth, openid, authentication, authorization, provider",
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <Menu pointing secondary style={{padding: '4em 0 2em', border: 0, margin: 0, height: 0}}>
      <Container>
        <Menu.Item active={section === "home"} as={Link} to="/">Home</Menu.Item>
        <Menu.Item active={section === "products"} as={Link} to="/products">Products</Menu.Item>
        <Menu.Item active={section === "docs"} as={Link} to="/docs">Docs</Menu.Item>
        <Menu.Item active={section === "playground"} as={Link} to="/playground">Playground</Menu.Item>
      </Container>
    </Menu>
    <div style={{flexGrow: 1}}>
      {children}
    </div>
    <Divider />
    <Segment style={{ borderRadius: 0, padding: '4em 4em 8em' }} vertical={true}>
      <Container>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column width={4}>
              <List link>
                <List.Item as={Link} to="/products"><h3>Products</h3></List.Item>
                <List.Item as={Link} to="/products/authentication">Authentication</List.Item>
                <List.Item as={Link} to="/products/mfa">Multifactor Authentication</List.Item>
                <List.Item as={Link} to="/products/branding">Branding</List.Item>
                <List.Item as={Link} to="/products/apps">Application Management</List.Item>
                <List.Item as={Link} to="/products/apis">API Management</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={4}>
              <List link>
                <List.Item as={Link} to="/docs"><h3>Documentation</h3></List.Item>
                <List.Item as={Link} to="/docs/fundamentals">Fundamentals</List.Item>
                <List.Item as={Link} to="/docs/guides">Guides</List.Item>
                <List.Item as={Link} to="/docs/develop">Develop</List.Item>
                <List.Item as={Link} to="/docs/reference/object">Object Reference</List.Item>
                <List.Item as={Link} to="/docs/reference/class">Class Reference</List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </>
);

export default Component;
